<template>
  <div>
    <template>
      <div v-if="alert.shown">
        <v-alert
          v-for="item in alert.data"
          :key="item.status"
          type="info"
          prominent
          border="left"
          dismissible
        >
          {{ item.status }}: {{ item.domains }}
        </v-alert>
      </div>
    </template>
    <div class="pb-4 px-4">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        placeholder="Type to search"
        outlined
        dense
        hide-details
        class="align-end"
      ></v-text-field>
    </div>
    <v-divider />
    <template v-if="selected?.length">
      <div class="px-3 py-3">
        <v-btn
          @click="handleDeleteMultiple(selected)"
          color="error"
          class="text-normal"
          >Delete selected <v-icon small right>mdi-trash-can</v-icon></v-btn
        >
      </div>
      <v-divider />
    </template>
    <v-data-table
      v-model="selected"
      show-select
      :loading="loading"
      :search="search"
      :headers="table.headers"
      :items="items"
      dense
    >
      <template #[`item.created_at`]="{ value }">
        <div style="white-space: nowrap">
          {{ value ? $moment(value).format("ll hh:mm:ss") : "" }}
        </div>
      </template>
      <template #[`item.name`]="{ item, value }">
        <div style="white-space: nowrap">
          {{ value }}
          <v-btn
            small
            icon
            color="primary"
            :href="item.link_task"
            target="_blank"
            title="Open in worksection"
          >
            <v-icon small> mdi-open-in-new </v-icon>
          </v-btn>
          <my-badge v-if="item.unread > 0" class="grey white--text">
            {{ item.unread }}
          </my-badge>
        </div>
      </template>

      <template #[`item.type`]="{ value }">
        <div style="white-space: nowrap">
          {{ value }}
        </div>
      </template>

      <template #[`item.progress`]="{ item }">
        <v-progress-circular
          :value="item.progress"
          color="deep-orange lighten-2"
          size="36"
          >{{ item.plan_done }}/{{ item.plan_all }}
        </v-progress-circular>
      </template>

      <template #[`item.new_deleted`]="{ item }">
        <BarChart :series="item.series"></BarChart>
      </template>

      <template #[`item.status`]="{ item }">
        <v-autocomplete
          v-model="item.status"
          :items="status"
          chips
          small-chips
          hide-details
          dense
          item-text="name"
          item-value="id"
          @change="set_status(item.id, item.status)"
          label="Status"
        >
          <template #selection="{ item }">
            <v-chip small :color="item.color">{{ item.name }}</v-chip>
          </template>
        </v-autocomplete>
      </template>

      <template #[`item.performer`]="{ item }">
        <span v-if="type === 1">
          <v-autocomplete
            v-model="item.performer_id"
            :items="performers"
            chips
            hide-details
            small-chips
            dense
            item-text="name"
            item-value="id"
            label="Performers"
            @change="set_data('performer', item.id, item.performer_id)"
          ></v-autocomplete>
        </span>
        <span v-else>{{ item.performer }}</span>
      </template>

      <template #[`item.daily_dynamic`]="{ item }">
        <span v-if="type === 1">
          <v-autocomplete
            v-model="item.daily_dynamic"
            :items="daily_dynamic"
            chips
            hide-details
            small-chips
            dense
            item-text="id"
            item-value="id"
            @change="set_data('daily_dynamic', item.id, item.daily_dynamic)"
            label="Daily Dynamic"
          ></v-autocomplete>
        </span>
        <span v-else>{{ item.daily_dynamic }}</span>
      </template>

      <template #[`item._action`]="{ item }">
        <div class="d-flex" style="gap: 0.25rem">
          <a
            :href="
              item.link_type_id === 86
                ? (level === 2
                    ? '/anchors-plan/tier2/placement-links/x/'
                    : '/anchors-plan/placement-links/x/') + item.id
                : (level === 2
                    ? '/anchors-plan/tier2/placement-links/'
                    : '/anchors-plan/placement-links/') + item.id
            "
            target="_blank"
          >
            <v-btn
              class="px-2 text-normal"
              style="min-width: 38px"
              color="primary"
              dark
              >LB
            </v-btn>
          </a>
          <div v-if="type === 1" class="d-flex" style="gap: 0.25rem">
            <a
              :href="
                '/anchors-plan/task/edit/' +
                item.id +
                '/' +
                (level === 2 ? 2 : 1)
              "
              target="_blank"
            >
              <v-btn class="px-2 text-normal" style="min-width: 38px"
                >SEO
              </v-btn>
            </a>

            <v-btn
              class="px-2 text-normal"
              style="min-width: 38px"
              @click="click_upload_donors(item)"
              ><v-icon>mdi-new-box</v-icon>
            </v-btn>

            <template>
              <span>
                <v-btn
                  class="px-2 text-normal"
                  style="min-width: 38px"
                  @click.stop="dialog.delete_line = true"
                  @click="destroy.task = item"
                  ><v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </span>
            </template>
          </div>
        </div>
      </template>
    </v-data-table>
    <upload-donors
      v-if="dialog.upload_donors === true"
      v-model="dialog.upload_donors"
      :link_type="upload_donors.link_type"
      :task="upload_donors.task"
      :project="upload_donors.project"
      :name="upload_donors.name"
      :performer="upload_donors.performer"
    ></upload-donors>
    <v-dialog
      v-model="dialog.delete_line"
      max-width="360"
      content-class="my-shadow--e2"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2 font-weight-bold pb-1">
          Delete task
          <v-spacer />
          <v-icon @click="dialog.delete_line = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="text-body-2 pt-0 pb-3">
          Are you sure you want to delete your task?
        </v-card-text>
        <v-divider />
        <v-card-text class="pt-3">
          Task name: <b>{{ destroy.task.name }}</b>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            large
            class="text-normal px-6"
            @click="dialog.delete_line = false"
          >
            Back
          </v-btn>

          <v-btn
            color="error"
            large
            class="text-normal px-6"
            @click="destroy_task()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog :retain-focus="false" v-model="rate.modal" max-width="1200px">
      <v-card class="elevation-16 mx-auto" width="300">
        <v-card-title class="headline"> Rate your linkbuilder </v-card-title>
        <v-card-text>
          Take a few seconds to evaluate your experience with your linkbuilder.
          It really helps!
          <div class="text-center">
            <b>Adherence to the deadline</b>
            <v-rating
              v-model="rate.meeting_deadline"
              color="green darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              hover
              large
            ></v-rating>
            <b>Observance of dynamics</b>
            <v-rating
              v-model="rate.observance_dynamics"
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              hover
              large
            ></v-rating>
            <b>Emotional attitude to this task</b>
            <v-rating
              v-model="rate.sincere_attitude_to_task"
              color="blue darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              hover
              large
            ></v-rating>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between">
          <v-btn text @click="rate.modal = false"> No Thanks </v-btn>
          <v-btn color="primary" @click="set_rate" text>Rate Now </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="aamIsAwaiting['delete']"
      max-width="360"
      content-class="my-shadow--e2"
    >
      <v-card flat outlined rounded="lg">
        <v-card-title class="text-body-2 font-weight-bold pb-1">
          Deleting multiple tasks
          <v-spacer />
          <v-icon @click="dialog.delete_line = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="text-body-2 pt-0 pb-3">
          Are you sure you want to delete selected tasks?
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            large
            class="text-normal px-6"
            @click="aamResolveAwaiting('reject', 'delete')"
          >
            Back
          </v-btn>

          <v-btn
            color="error"
            large
            class="text-normal px-6"
            @click="aamResolveAwaiting('resolve', 'delete')"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import BarChart from "./BarChart";
import UploadDonors from "./UploadDonors";
import eventPipe from "@/events/eventPipe";
import MyBadge from "../../UI/MyBadge.vue";
import Service from "../../../plugins/service";
import AwaitResolveMixin from "../../../mixins/utils/AwaitResolveMixin";

export default {
  name: "TableAnchorPlanIndex",
  props: ["items", "performers", "status", "type", "level", "loading"],
  components: { MyBadge, BarChart, UploadDonors },
  mixins: [AwaitResolveMixin],
  metaInfo: {
    title: "Anchor Plan Tasks - LTT - SI",
  },
  data() {
    return {
      selected: [],
      search: "",
      loadings: {},
      alert: {
        data: [],
        shown: false,
      },
      rate: {
        modal: false,
        task_id: false,
        meeting_deadline: 1,
        observance_dynamics: 1,
        sincere_attitude_to_task: 1,
      },
      daily_dynamic: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
        { id: 9 },
        { id: 10 },
        { id: 11 },
        { id: 12 },
        { id: 13 },
        { id: 14 },
        { id: 15 },
      ],
      series: [
        {
          name: "new",
          data: [44],
        },
        {
          name: "used",
          data: [53],
        },
        {
          name: "problem",
          data: [12],
        },
        {
          name: "pending",
          data: [9],
        },
      ],
      destroy: {
        task: {},
      },
      dialog: {
        upload_donors: false,
        delete_line: false,
      },
      upload_donors: {
        domains: [],
        link_type: undefined,
        task: undefined,
        project: undefined,
        name: undefined,
        performer: undefined,
      },
      table: {
        headers: [
          { text: "name", title: "name", value: "name", class: "text-center" },
          { text: "type", title: "type", value: "type", class: "text-left" },
          // {
          //   text: "link_task",
          //   title: "link_task",
          //   value: "link_task",
          //   class: "text-left",
          // },
          {
            text: "progress",
            title: "progress",
            value: "progress",
            class: "text-left",
          },
          {
            text: "donor progress",
            title: "donor progress",
            value: "new_deleted",
            class: "text-left",
          },
          {
            text: "status",
            title: "status",
            value: "status",
            class: "text-left",
          },
          {
            text: "creator",
            title: "creator",
            value: "creator",
            class: "text-left",
          },
          {
            text: "responsible",
            title: "performer",
            value: "performer",
            class: "text-left",
          },
          {
            text: "department",
            title: "department",
            value: "department",
            class: "text-left",
          },
          // {
          //   text: "daily dynamic",
          //   title: "dynamic",
          //   value: "daily_dynamic",
          //   class: "text-left",
          // },
          // {
          //   text: "quiet mode",
          //   title: "quiet_mode",
          //   value: "quiet_mode",
          //   class: "text-left",
          // },
          {
            text: "created_at",
            title: "created_at",
            value: "created_at",
            class: "text-left",
          },
          { text: "id", title: "id", value: "id", class: "text-center" },
          { text: "", title: "", value: "_action", class: "text-left" },
        ],
      },
    };
  },
  methods: {
    async handleDeleteMultiple(items = []) {
      try {
        await this.aamWait("delete");

        const promises = [];

        items?.forEach((item) => promises.push(this.deleteTask(item)));

        Promise.allSettled(promises).then(() => {
          this.selected = [];

          this.$nextTick(() => {
            this.$emit("fetch_data");
          });
        });
      } catch {
        //
      }
    },
    async deleteTask(tableRow) {
      try {
        this.loadings.deleting = tableRow.id;

        await Service.post(`/anchor-plan/tasks/action/destroy/${tableRow.id}`);
      } catch (e) {
        console.error("Error while deleting task.", e);
      } finally {
        this.loadings.deleting = null;
      }
    },
    createTimer(item) {
      eventPipe.$emit("create-timer", item.name, item.id, "ap", []);
    },
    click_upload_donors(item) {
      this.dialog.upload_donors = true;
      this.upload_donors.link_type = item.link_type_id;
      this.upload_donors.task = item.id;
      this.upload_donors.project = this.id_project;
      this.upload_donors.name = item.name;
      this.upload_donors.performer = item.performer_id;
    },
    destroy_task() {
      this.update_loading("destroy_task", true);

      let url = `${self.$store.state.server_url}/anchor-plan/tasks/action/destroy/${this.destroy.task.id}`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      this.dialog.delete_line = false;

      axios
        .post(url, {}, config)
        .then(function () {
          this.$emit("fetch_data");
          this.update_loading("destroy_task", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    set_rate() {
      let self = this;

      self.update_loading("set_rate", true);

      let url = `${self.$store.state.server_url}/anchor-plan/tasks/set/rate`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        id: self.rate.task_id,
        rate_dynamics: self.rate.observance_dynamics,
        rate_deadline: self.rate.meeting_deadline,
        rate_emotional: self.rate.sincere_attitude_to_task,
      };

      axios
        .post(url, data, config)
        .then(function () {
          self.update_loading("set_rate", false);
          self.rate.modal = false;
        })
        .catch(function (error) {
          alert(error);
        });
    },
    set_status(task_id, status_id) {
      let self = this;

      self.update_loading("set_status", true);

      let url = `${self.$store.state.server_url}/anchor-plan/tasks/set/status`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        task_id,
        status_id,
      };

      axios
        .post(url, data, config)
        .then(function () {
          self.update_loading("set_status", false);
          if (status_id === 5) {
            self.rate.modal = true;
            self.rate.task_id = task_id;
          }
        })
        .catch(function (error) {
          alert(error);
        });
    },
    set_data(type, task_id, value) {
      let self = this;

      self.update_loading("set_data", true);

      let url = `${self.$store.state.server_url}/anchor-plan/tasks/set/data`;

      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.$store.state.auth.token,
        },
      };

      let data = {
        type,
        task_id,
        value,
      };

      axios
        .post(url, data, config)
        .then(function () {
          self.update_loading("set_data", false);
        })
        .catch(function (error) {
          alert(error);
        });
    },
    update_loading(type, value) {
      this.$store.state.loading = false;
      this.loadings[type] = value;
      for (let i in this.loadings) {
        if (this.loadings[i] === true) {
          this.$store.state.loading = true;
        }
      }
    },
  },
  computed: {
    isset_active_project() {
      return this.id_project !== null && this.id_project !== undefined;
    },
    id_project() {
      return this.$store.state.project.active_project;
    },
  },
  created() {},
};
</script>

<style scoped></style>
