<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <page-header
          title="Monobrands Main dashboard"
          :description="pageDescription"
          :breadcrumbs="pageBreadcrumbs"
        />
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.team"
              :items="filtersData?.teams"
              :loading="filtersDataPendingKeys?.teams"
              hide-details
              dense
              outlined
              multiple
              label="Team"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.country"
              :items="filtersData?.countries"
              :loading="filtersDataPendingKeys?.countries"
              hide-details
              dense
              outlined
              multiple
              label="Country"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.seo"
              :items="filtersData?.users"
              :loading="filtersDataPendingKeys?.users"
              hide-details
              item-text="email"
              item-value="id"
              dense
              outlined
              multiple
              label="SEO"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-autocomplete
              v-model="filters.responsible"
              :items="filtersData?.users"
              :loading="filtersDataPendingKeys?.users"
              hide-details
              item-text="email"
              item-value="id"
              dense
              outlined
              multiple
              label="Responsible"
              placeholder="Type to search"
            />
          </v-col>
          <v-col cols="6" md="4" lg="2" xl="fill"></v-col>
          <v-col cols="6" md="4" lg="2" xl="fill">
            <v-btn
              block
              color="primary"
              @click="handleGetData"
              class="text-normal"
              height="40"
              :loading="loadings.table"
            >
              Get data
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card flat outlined rounded="lg">
          <v-card-title>
            <v-text-field
              v-model="search"
              placeholder="Type to search"
              append-icon="mdi-magnify"
              clearable
              dense
              outlined
              hide-details
            />
          </v-card-title>
          <v-divider />
          <v-card-text class="px-0">
            <v-data-table :items="table.items" :headers="table.headers">
              <template
                v-for="h in table.headers"
                #[`header.${h.value}`]="{ header }"
              >
                <v-tooltip
                  v-if="h.tooltip"
                  bottom
                  :key="h.text"
                  max-width="320"
                >
                  <template v-slot:activator="{ on }">
                    <span v-on="on">
                      {{ header.text }}
                      <v-icon size="14" style="opacity: 0.5"
                        >mdi-information-outline</v-icon
                      >
                    </span>
                  </template>
                  <span>{{ header.tooltip }}</span>
                </v-tooltip>
                <template v-else>
                  {{ h.text }}
                </template>
              </template>

              <template #[`item.project`]="{ value }">
                {{ value }}
                <v-btn
                  icon
                  color="primary"
                  small
                  :href="`https://${value}`"
                  target="_blank"
                  ><v-icon small>mdi-open-in-new</v-icon></v-btn
                >
              </template>

              <template #[`item.position`]="{ value, item }">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ value }}
                      <v-icon style="opacity: 0.5" small>mdi-key</v-icon>
                    </span>
                  </template>
                  {{ item.position_key }}
                </v-tooltip>
              </template>

              <template #[`item.best_position`]="{ value, item }">
                <v-tooltip bottom>
                  <template #activator="{ on }">
                    <span v-on="on">
                      {{ value }}
                      <v-icon style="opacity: 0.5" small>mdi-key</v-icon>
                    </span>
                  </template>
                  {{ item.best_position_key }}
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { PAGE_BREADCRUMBS, PAGE_DESCRIPTION, TABLE_HEADERS } from "./defaults";
import PageHeader from "@/components/UI/PageHeader.vue";
import UploadFiltersDataMixin from "@/mixins/UploadFiltersDataMixin";

export default {
  components: { PageHeader },

  mixins: [UploadFiltersDataMixin],

  data: () => ({
    pageBreadcrumbs: PAGE_BREADCRUMBS,
    pageDescription: PAGE_DESCRIPTION,
    search: "",
    filters: {
      team: [],
      country: [],
      seo: [],
      responsible: [],
    },
    loadings: {
      table: false,
    },
    table: {
      items: [
        {
          project: "test.project",
          money: 10,
          ftd: 10,
          ltt_links: 10,
          position: 10,
          best_position: 10,
          position_key: "position key",
          best_position_key: "best position key",
        },
      ],
      headers: TABLE_HEADERS,
    },
  }),

  mounted() {
    this.uploadFiltersData(["countries", "users", "teams"]);
  },

  methods: {
    handleGetData() {},
  },
};
</script>

<style lang="scss" scoped></style>
