<template>
  <div>
    <h2>
      {{ title }}
      <v-menu
        v-if="description"
        content-class="rounded-lg my-shadow--e2"
        max-width="420"
      >
        <template #activator="{ on }">
          <v-icon v-on="on">mdi-information-outline</v-icon>
        </template>
        <v-card flat outlined rounded="lg" class="pa-4">
          <div v-html="description" />
        </v-card>
      </v-menu>
    </h2>
    <v-breadcrumbs class="pa-0" :items="breadcrumbs" />
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Page title",
    },
    description: {
      type: String,
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
