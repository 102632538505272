export const PAGE_DESCRIPTION = `
    <b>Monobrands Main Dashboard</b> gives you a quick overview of the current status of active monobrand websites. It shows key data like revenue, number of FTDs, built links, and ranking performance — all in one place. <br/><br/>

Use the filters to display only the projects you need, based on the assigned manager, team, or region.
`;
export const PAGE_BREADCRUMBS = [
  { text: "Home", to: "/" },
  { text: "Monobrands", disabled: true },
  { text: "Main dashoard", disabled: true },
];

export const TABLE_HEADERS = [
  {
    text: "Project",
    value: "project",
    tooltip: "All Projects with Project Category = monobrands",
  },
  {
    text: "Money",
    value: "money",
    tooltip:
      "Total project income over the past 12 months, based on data from https://app.serpinsider.com/ndc/manage/charts",
  },
  {
    text: "FTD",
    value: "ftd",
    tooltip:
      "Total number of FTDs generated by the project over the past 12 months, as reported on https://app.serpinsider.com/ndc/manage/charts",
  },
  {
    text: "LTT Links",
    value: "ltt_links",
    tooltip: "Total number of LTT links acquired over the past 12 months.",
  },
  {
    text: "Position",
    value: "position",
    tooltip:
      "Current highest-ranking keyword position for the domain based on data from https://app.serpinsider.com/positions/v3",
  },
  {
    text: "Best Position",
    value: "best_position",
    tooltip:
      "Highest keyword position achieved by the domain in the last 30 days, based on data from https://app.serpinsider.com/positions/v3",
  },
];
