<template>
  <v-data-table
    :search="search"
    :headers="headers || []"
    :items="items || []"
    :sort-by="['priority']"
    :sort-desc="[true]"
    :items-per-page="-1"
    item-key=""
    class="link-dynamics-report-links-by-project-table relative-tr"
    dense
  >
    <template v-for="h in headers" #[`item.${h.value}`]="{ item, value }">
      <template v-if="h.value === 'project'">
        <div
          class="mark"
          :style="get_style_row(item.lag_on_page)"
          :key="`mark.${h.value}`"
        />
        <div
          :key="`mark.2.${h.value}`"
          class="px-3 d-flex align-center"
          style="white-space: nowrap"
          :style="{
            'border-left': `3px solid ${to_color(hash_code(value))}`,
            height: '100%',
          }"
        >
          {{ value }}
        </div>
      </template>
      <template v-else-if="h.value === 'fact'">
        <div class="d-flex align-start" :key="`fact.${h.value}`">
          <div class="d-flex flex-column">
            <a
              :href="get_link_to_ltt(item.id, item.subproject_id, item.type_id)"
              target="_blank"
            >
              {{ item.fact.all.all }}</a
            >

            <div v-if="item.check" title="Wait fix" class="error--text">
              {{ item.check }}
            </div>
          </div>
          <div style="white-space: nowrap">
            <span></span
            ><span v-if="item.fact.on_page.all > 0"
              >({{ item.fact.on_page.all
              }}<span v-if="item.fact.gi.all > 0">
                / {{ item.fact.gi.all }}</span
              >)
            </span>
          </div>
        </div>
      </template>
      <template v-else-if="$moment(h.value, 'YYYY-MM-DD', true).isValid()">
        <LinksByProjectTableDateCellMinimized
          v-if="item.dates[h.value]"
          :key="`date.${h.value}`"
          :date="item.dates[h.value]"
          :link="
            get_link_to_ltt(
              item.id,
              item.subproject_id,
              item.type_id,
              item.dates[h.value].date
            )
          "
        />
      </template>
      <template v-else-if="h.value === 'done'">
        {{ item.done_on_page ? item.done_on_page + "%" : item.done_on_page }}
      </template>
      <template v-else-if="h.value === 'left'">
        <div
          :key="`left.${h.value}`"
          v-if="item.left"
          style="white-space: nowrap"
        >
          <span v-if="item.left.performer > 0">
            {{ item.left.plan }}
          </span>
          <span v-else>
            <v-icon small color="red">mdi-crop</v-icon>
          </span>
        </div>
      </template>
      <template v-else-if="h.value === 'created_at'">
        <div v-for="(i, j) in value" :key="`date.${j}.${h.value}`">
          <div :title="i.department" style="white-space: nowrap">
            {{ i.date }}
          </div>
        </div>
      </template>
      <template v-else-if="h.value === 'seo'">
        <div :key="`seo.${h.value}`" style="white-space: nowrap">
          {{ value }}
        </div>
      </template>
      <template v-else-if="h.value === 'link_task'">
        <v-chip
          :key="`link_task.${h.value}`"
          label
          :href="value"
          target="_blank"
          class="my-1"
          color="transparent"
        >
          Visit <v-icon small right>mdi-open-in-new</v-icon></v-chip
        >
      </template>
      <template v-else-if="h.value === 'test'"> </template>
      <template v-else>
        {{ value }}
      </template>
    </template>
  </v-data-table>
</template>

<script>
import LinksByProjectTableDateCellMinimized from "./LinksByProjectTableDateCellMinimized.vue";

export default {
  components: {
    LinksByProjectTableDateCellMinimized,
  },
  props: ["search", "items", "headers", "filter"],

  methods: {
    get_link_to_ltt(project, subproject, type = false, date = false) {
      let url =
        "/ltt/index?project_id=" + project + "&subproject=" + subproject;

      if (date) {
        url += "&date_range=" + date + "," + date;
      } else {
        url += "&date_range=" + this.filter.date_range;
      }

      if (type) {
        url += "&type=" + type;
      }
      return url;
    },
    get_style_project(name) {
      let val = this.hash_code(name);
      return (
        "border-left: 3px solid " + this.to_color(val) + "; padding: 7px; "
      );
    },
    hash_code(str) {
      let hash = 0,
        i,
        chr;
      for (i = 0; i < str.length; i++) {
        chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    to_color(num) {
      num >>>= 0;
      var b = num & 0xff,
        g = (num & 0xff00) >>> 8,
        r = (num & 0xff0000) >>> 16,
        a = ((num & 0xff000000) >>> 24) / 10;
      return "rgba(" + [r, g, b, a].join(",") + ")";
    },
    get_style_row(val) {
      let color = "";
      if (val > 50) {
        color = "rgba(255,112,112,0.14)";
      } else if (val > 20) {
        color = "rgba(255,244,131,0.14)";
      }
      return "background-color:" + color;
    },
  },
};
</script>

<style lang="scss">
.link-dynamics-report-links-by-project-table {
  tbody {
    tr {
      td:first-child {
        padding: 0 !important;
      }
    }
  }
}
</style>
